import initScrollReveal from "./scripts/scrollReveal";
import initTiltEffect from "./scripts/tiltAnimation";
import initTypewriterEffect from "./scripts/typewriterEffect";
import { targetElements, defaultProps } from "./data/scrollRevealConfig";

initScrollReveal(targetElements, defaultProps);
initTiltEffect();
initTypewriterEffect();

const handleSubmit = (e) => {
  e.preventDefault();

  let myForm = document.getElementById("contact_form");
  let formData = new FormData(myForm);

  fetch("/", {
    method: "POST",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    body: new URLSearchParams(formData).toString(),
  })
    .then(() => {
      document
        .getElementById("success_contact_message")
        .classList.remove("d-none");
      e.target.reset();
    })
    .catch((error) => console.log(error));
};

export default function initApp() {
  document
    .querySelector("#contact_form")
    .addEventListener("submit", handleSubmit);
}

initApp();
