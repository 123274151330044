import Typewriter from "typewriter-effect/dist/core";

export default function initTypewriterEffect() {
  new Typewriter("#typewriter", {
    strings: [
      "sanitair",
      "verwarming",
      "elektriciteit",
      "renovatie",
      "dakwerken",
    ],
    autoStart: true,
    delay: 100,
    loop: true,
  });
}
